import { action, makeObservable, observable } from 'mobx';
import toast from 'react-hot-toast';
import { RootStore } from '..';
import { referral } from 'constant';
import { checkout, ecommerceCheckout, partnerOrderCheckout } from 'requests/cart';
import { parseError } from 'utils';
import Alert from 'utils/Alert';
import storage from 'utils/storage';
import { SessionStorageUtility } from 'utils/session-util';
import { OrderKeyMappping } from 'constant/order';

export class CheckoutStore {
  selectedAddress = 'default';
  recipientPay = false;
  thirdPartyOrder = false;
  newOrderAddressForm = false;
  isChangeDeliveryAddressOpen = false;
  isDeliveryAddressCardOpen = false;
  isCityValid = false;
  addressToEditIndex = '';
  deliveryAddress: IAddress = {
    firstName: '',
    lastName: '',
    email: '',
    street: '',
    city: '',
    state: '',
    businessId: '',
    longitude: '',
    latitude: '',
    phoneNumber: '',
    landmark: ''
  };
  validatedCoupon = null as ICoupon | null;
  isCheckoutPaymentOpen = false;
  isNew = '';
  loading = {
    customerCheckout: false
  };

  checkoutAmount = '';

  guestAddressForm = true;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      selectedAddress: observable,
      thirdPartyOrder: observable,
      newOrderAddressForm: observable,
      recipientPay: observable,
      deliveryAddress: observable,
      guestAddressForm: observable,
      isChangeDeliveryAddressOpen: observable,
      isDeliveryAddressCardOpen: observable,
      isCityValid: observable,
      addressToEditIndex: observable,
      validatedCoupon: observable,
      isCheckoutPaymentOpen: observable,
      loading: observable,
      isNew: observable,
      checkoutAmount: observable,

      selectAddress: action.bound,
      toggleNewOrderAddressForm: action.bound,
      toggleGuestAddressForm: action.bound,
      setDeliveryAddress: action.bound,
      setAddressToEditIndex: action.bound,
      toggleChangeDeliveryAddress: action.bound,
      resetOptions: action.bound,
      toggleDeliveryAddressCard: action.bound,
      orderCheckout: action.bound,
      setNewFamAndFriend: action.bound,
      setRecipientPay: action.bound,
      setValidatedCoupon: action.bound,
      toggleCheckoutPaymentOption: action.bound,
      orderAdminCheckout: action.bound,
      adminPartnerCheckout: action.bound,
      setCheckoutAmount: action.bound,
      setIsDeliveryAddressCardOpen: action.bound,
      setIsValidCity: action.bound
    });
    this.rootStore = rootStore;
  }
  setAddressToEditIndex(index: string) {
    this.addressToEditIndex = index;
  }

  setIsDeliveryAddressCardOpen = (value: boolean) => {
    this.isDeliveryAddressCardOpen = value;
  };

  setIsValidCity = (value: boolean) => {
    this.isCityValid = value;
  };
  setNewFamAndFriend(value: string) {
    this.rootStore.UserStore.newFamAndFriend = value;
  }
  toggleDeliveryAddressCard() {
    this.isDeliveryAddressCardOpen = !this.isDeliveryAddressCardOpen;
  }

  toggleChangeDeliveryAddress() {
    this.isChangeDeliveryAddressOpen = !this.isChangeDeliveryAddressOpen;
  }

  selectAddress(address: string, thirdParty: boolean) {
    this.selectedAddress = address;
    this.thirdPartyOrder = thirdParty;
  }

  toggleNewOrderAddressForm() {
    if (!this.newOrderAddressForm) {
      this.rootStore.UserStore.newFamAndFriend = 'new';
    } else {
      this.rootStore.UserStore.newFamAndFriend = '';
    }
    this.newOrderAddressForm = !this.newOrderAddressForm;
  }

  toggleGuestAddressForm() {
    this.guestAddressForm = !this.guestAddressForm;
  }

  toggleCheckoutPaymentOption() {
    this.isCheckoutPaymentOpen = !this.isCheckoutPaymentOpen;
  }

  resetOptions() {
    this.newOrderAddressForm = false;
    this.isDeliveryAddressCardOpen = false;
    this.isChangeDeliveryAddressOpen = false;
    this.setIsDeliveryAddressCardOpen(false);
    this.addressToEditIndex = '';
  }

  setRecipientPay() {
    this.recipientPay = !this.recipientPay;
  }

  setDeliveryAddress(address: IAddress) {
    this.deliveryAddress = address;
  }

  setValidatedCoupon(coupon: any) {
    this.validatedCoupon = coupon;
  }

  setCheckoutAmount(amount: string) {
    this.checkoutAmount = amount;
  }

  async orderCheckout(checkoutOptions?: ICheckoutOptions) {
    try {
      if (checkoutOptions) {
        this.rootStore.OrderStore.paymentProvider = checkoutOptions.provider;
        this.loading.customerCheckout = true;
        if (!checkoutOptions.email) {
          checkoutOptions.email = this.deliveryAddress.email;
        }
      }
      this.rootStore.CartStore.loading = true;
      const { isAuthenticated } = this.rootStore.AuthStore;
      const { cart, cartTotals } = this.rootStore.CartStore;

      const orderItems: IOrderItemWithoutAmount[] = cart.map((item) => {
        if (!item.brandName) {
          throw new Error('Brand name cannot be empty');
        }
        return {
          drugId: item.drugGeneratedId,
          quantity: item.quantity,
          drugName: item.brandName,
          dosageForm: item.dosageForm,
          strength: item.strength,
          price: item.price,
          totalPrice: item.totalPrice,
          unit: item.unit,
          manufacturer: item.manufacturer,
          category: item.category,
          itemType: item.itemType,
          fileUrl: item.fileUrl
        };
      });

      const { deliveryFee, sampleCollectionFee } = cartTotals;
      const deliveryAddress = this.deliveryAddress as IDeliveryAddress;
      const isAddressEmpty = Object.entries(deliveryAddress).some(([key, value]) => {
        return (
          key !== 'latitude' &&
          key !== 'longitude' &&
          (value === null || value === '' || value === undefined)
        );
      });

      if (isAddressEmpty) {
        toast.error('Please save your address information', { position: 'top-right' });
        this.rootStore.CartStore.loading = false;
        return;
      }


      const isSubscription  = SessionStorageUtility.get<boolean>(OrderKeyMappping.IS_SUBSCRIPTION) || false;
      // const {
      //   packType: { pack, duration, frequency, startDate, endDate },
      //   isSubscription
      // } = this.rootStore.OrderStore;

      const payload: OrderDto = {
        isSubscription,
        deliveryAddress,
        orderItems,
        deliveryFee,
        sampleCollectionFee,
        recipientPay: this.recipientPay,
        serviceCharge: 0,
        isFaf: this.thirdPartyOrder,
        userId: isAuthenticated ? this.rootStore.AuthStore.user?.userId : null,
        referrer: this.thirdPartyOrder ? null : storage.get(referral) || null,
        hasCoupon: this.validatedCoupon && !isSubscription ? true : false,
        couponCode: this.validatedCoupon?.couponCode,
        couponValue: this.validatedCoupon?.couponValue,
        couponType: this.validatedCoupon?.couponType
      };

      if (isSubscription) {

        const packType = SessionStorageUtility.get<IPharmPackage>(OrderKeyMappping.SUBSCRIPTIONDATA);
        if (!packType) {
          toast.error('Subscription data not found', { position: 'top-right' });
          this.rootStore.CartStore.loading = false;
          return;
        }
       const  { pack, duration, frequency, startDate, endDate } = packType;
        payload.pharmaPackage = pack;
        payload.duration = duration;
        payload.frequency = frequency;
        payload.startDate = startDate;
        payload.endDate = endDate;

        const discountDetails = this.rootStore.OrderStore.discountDetails;
        payload.discountDuration = discountDetails?.discountDuration;
        payload.discountPercentage = discountDetails?.discountPercentage;
      }

      let response = null;
      if (this.rootStore.OrderStore.isPaymentProvidersOptionsOpen && checkoutOptions) {
        response = await ecommerceCheckout(payload, checkoutOptions);
      } else {
        response = await checkout(payload);
      }

      if (response && response?.status) {
        this.rootStore.CartStore.clearCart();

        if (isSubscription) {
          this.rootStore.OrderStore.resetDiscountDetails()
        }
        
        if (checkoutOptions?.paymentType === 'wallet' && payload.userId) {
          this.rootStore.FinanceStore.getWalletById(payload.userId);
        }

        this.rootStore.CartStore.loading = false;
        this.validatedCoupon = null;
        toast.success(response.message, { position: 'top-right' });
        return { ...response, data: checkoutOptions && response.data };
      } else {
        this.rootStore.CartStore.loading = false;
        toast.error(response?.message || 'An error occurred', { position: 'top-right' });
        return false;
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
      return false;
    } finally {
      this.rootStore.CartStore.loading = false;
      this.loading.customerCheckout = false;
    }
  }

  async orderAdminCheckout(checkoutOptions?: ICheckoutOptions) {
    try {
      if (checkoutOptions) {
        this.rootStore.OrderStore.paymentProvider = checkoutOptions.provider;
        this.loading.customerCheckout = true;
        if (!checkoutOptions.email) {
          checkoutOptions.email = this.deliveryAddress.email;
        }
      }
      this.rootStore.CartStore.loading = true;
      const { cart, cartTotals } = this.rootStore.CartStore;
      const { selectedUser } = this.rootStore.UserStore;
      const orderItems: IOrderItemWithoutAmount[] = cart.map((item) => {
        if (!item.brandName) {
          throw new Error('Brand name cannot be empty');
        }
        return {
          drugId: item.drugGeneratedId,
          quantity: item.quantity,
          drugName: item.brandName,
          dosageForm: item.dosageForm,
          strength: item.strength,
          price: item.price,
          totalPrice: item.totalPrice,
          unit: item.unit,
          manufacturer: item.manufacturer,
          category: item.category,
          itemType: item.itemType,
          fileUrl: item.fileUrl
        };
      });

      const { deliveryFee, sampleCollectionFee } = cartTotals;
      const checkAddress =
        JSON.stringify(selectedUser) === '{}' || selectedUser?.status === false
          ? this.deliveryAddress
          : (this.deliveryAddress as any);
      const checkUser =
        JSON.stringify(selectedUser) === '{}' || selectedUser?.status === false
          ? null
          : (selectedUser?.userId as string);

      const isAddressEmpty = Object.entries(checkAddress).some(([key, value]) => {
        return (
          key !== 'latitude' &&
          key !== 'longitude' &&
          (value === null || value === '' || value === undefined)
        );
      });

      if (isAddressEmpty) {
        toast.error('Please save your address information', { position: 'top-right' });
        this.rootStore.CartStore.loading = false;
        return;
      }

      const { isSubscription } = this.rootStore.OrderStore;

      const payload: OrderDto = {
        isSubscription,
        deliveryAddress: checkAddress,
        orderItems,
        email: this.deliveryAddress.email,
        deliveryFee,
        sampleCollectionFee,
        recipientPay: this.recipientPay,
        serviceCharge: 0,
        isFaf: this.thirdPartyOrder,
        userId: checkUser,
        orderDate: cart[0]?.orderDate === '' ? undefined : (cart[0].orderDate as string),
        referrer: this.thirdPartyOrder ? null : storage.get(referral) || null,
        hasCoupon: this.validatedCoupon ? true : false,
        couponCode: this.validatedCoupon?.couponCode,
        couponValue: this.validatedCoupon?.couponValue,
        couponType: this.validatedCoupon?.couponType,
        b2b: selectedUser?.isBusiness
      };

      if (selectedUser?.isBusiness) {
        payload.isLogged = false;
      }

      let response = null;

      if (this.isCheckoutPaymentOpen && checkoutOptions) {
        response = await ecommerceCheckout(payload, checkoutOptions);
      } else {
        response = await checkout(payload);
      }

      if (response && response?.status) {
        this.rootStore.CartStore.clearCart();
        this.rootStore.CartStore.loading = false;
        this.validatedCoupon = null;
        toast.success(response.message, { position: 'top-right' });
        return { ...response, data: checkoutOptions && response.data };
      } else {
        this.rootStore.CartStore.loading = false;
        toast.error(response?.message || 'An error occurred', { position: 'top-right' });
        return false;
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
      return false;
    } finally {
      this.rootStore.CartStore.loading = false;
      this.loading.customerCheckout = false;
    }
  }
  async adminPartnerCheckout(partnerId: string) {
    try {
      this.rootStore.CartStore.loading = true;
      const { cart, cartTotals } = this.rootStore.CartStore;
      const { selectUserProfile } = this.rootStore.UserStore;
      const orderItems: IOrderItemWithoutAmount[] = cart.map((item) => {
        if (!item.brandName) {
          throw new Error('Brand name cannot be empty');
        }
        return {
          drugId: item.drugGeneratedId,
          quantity: item.quantity,
          drugName: item.brandName,
          dosageForm: item.dosageForm,
          strength: item.strength,
          price: item.price,
          totalPrice: item.totalPrice,
          unit: item.unit,
          manufacturer: item.manufacturer,
          category: item.category,
          itemType: item.itemType,
          fileUrl: item.fileUrl
        };
      });
      const { deliveryFee, sampleCollectionFee } = cartTotals;

      const checkAddress =
        JSON.stringify(selectUserProfile) === '{}' || selectUserProfile?.status === false
          ? this.deliveryAddress
          : (this.deliveryAddress as any);
      const isAddressEmpty = Object.entries(checkAddress).some(([key, value]) => {
        return key !== 'latitude' && key !== 'longitude' && (value === null || value === undefined);
      });

      if (isAddressEmpty) {
        toast.error('Please save your address information', { position: 'top-right' });
        this.rootStore.CartStore.loading = false;
        return;
      }
      const { isSubscription } = this.rootStore.OrderStore;
      const payload: OrderDto = {
        isSubscription,
        deliveryAddress: checkAddress,
        orderItems,
        deliveryFee,
        isGuest: false,
        sampleCollectionFee,
        recipientPay: this.recipientPay,
        serviceCharge: 0,
        isFaf: this.thirdPartyOrder,
        userId: partnerId,
        orderDate: cart[0]?.orderDate === '' ? undefined : (cart[0].orderDate as string),
        referrer: this.thirdPartyOrder ? null : storage.get(referral) || null,
        hasCoupon: this.validatedCoupon ? true : false,
        couponCode: this.validatedCoupon?.couponCode,
        couponValue: this.validatedCoupon?.couponValue,
        couponType: this.validatedCoupon?.couponType,
        isLogged: false,
        enrolleeId: checkAddress.businessId || '',
        memberId: checkAddress._id
      };
      if (!isAddressEmpty) {
        const response = (await partnerOrderCheckout(payload)) as any;

        if (response && response?.status) {
          this.rootStore.CartStore.clearCart();
          this.rootStore.CartStore.loading = false;
          this.validatedCoupon = null;
          return response;
        } else {
          this.rootStore.CartStore.loading = false;
          toast.error(response?.message || 'An error occurred', { position: 'top-right' });
          return false;
        }
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
      return false;
    } finally {
      this.rootStore.CartStore.loading = false;
      this.loading.customerCheckout = false;
    }
  }
}
