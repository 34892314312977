import toast from 'react-hot-toast';

export const ACCESS_TOKEN = '_USR-TK_';
export const REFRESH_TOKEN_KEY = '_USR_RF_TK_';
export const userKey = '_USR_';
export const cartKey = '_USR_CRT_';
export const cartPricing = '_USR_CRT_PC_';
export const adminNoticeKey = '_PR_NOT_BOARD_';
export const referral = '_USR_REFERRAL_';
export const ENCRYPTION_KEY = 'usr-encry-tks';

export const Routes = {
  Login: '/auth/login',
  Signup: '/auth/signup',
  ForgotPassword: '/auth/forgot-password',
  EmailSent: '/auth/email-sent',
  ResetPassword: '/auth/reset-password',
  OnBoarding: '/auth/onboarding',
  Home: '/my-orders',
  ClientAccount: '/account',
  PlaceOrder: '/',
  ClientOrdersAddress: '/place-order/address',
  GuestConfirmationPage: '/auth/order-success',
  Contact: '/contact',
  Members: '/members',
  Reports: '/reports',
  AdminLogin: '/auth/admin/login',
  AdminDashboard: '/admin/dashboard',
  AdminOrders: '/admin/orders',
  AdminOrdersList: '/admin/orders/all',
  AdminOrdersCustomerSuccess: '/admin/orders/customer-success',
  AdminOrdersPartnerSuccess: '/admin/orders/partner-success',
  AdminOrdersVendorManagement: '/admin/orders/vendor-management',
  AdminOrdersQualityAssurance: '/admin/orders/quality-assurance',
  AdminOrdersPickup: '/admin/orders/pick-up',
  AdminOrdersIssuesResolution: '/admin/orders/issues-resolution',
  AdminOrdersDispensing: '/admin/orders/dispensing',
  AdminOrdersDelivery: '/admin/orders/delivery',
  AdminOrdersCompleted: '/admin/orders/completed',
  AdminSubscriptionList: '/admin/orders/subscriptions',
  AdminServices: '/admin/services',
  AdminConsultationService: '/admin/services/consultation',
  AdminWellnessService: '/admin/services/wellness',
  AdminWellnessDetails: (type: string) => `/admin/services/wellness/${type}/wellness-details`,
  AdminConsultationSummary: (id: string) =>
    `/admin/services/consultation/${id}/consultation-summary`,
  AdminConsultationDetails: (id: string) => `/admin/services/${id}/consultation-summary/details`,
  AdminConsultationActivities: (id: string) =>
    `/admin/services/${id}/consultation-summary/activities`,
  AdminOrderSummaryPage: (orderNumber: string) => `/admin/${orderNumber}/order`,
  AdminOrderDetails: (orderNumber: string) => `/admin/${orderNumber}/order-summary/details`,
  AdminEditOrder: (orderNumber: string) => `/admin/${orderNumber}/order-summary/edit`,
  AdminOrderActivities: (orderNumber: string) => `/admin/${orderNumber}/order-summary/activities`,
  AdminOrderStatusTimeStamps: (orderNumber: string) =>
    `/admin/${orderNumber}/order-summary/timestamps`,
  AdminSubscriptionDetails: (orderNumber: string) =>
    `/admin/subscription/${orderNumber}/summary/details`,
  AdminEditSubscription: (orderNumber: string) => `/admin/subscription/${orderNumber}/summary/edit`,
  AdminSubscriptionActivities: (orderNumber: string) =>
    `/admin/subscription/${orderNumber}/summary/activities`,
  AdminSubscriptionStatusTimeStamps: (orderNumber: string) =>
    `/admin/subscription/${orderNumber}/summary/timestamps`,

  AdminCustomer: '/admin/customers',
  AdminPlaceOrderForCustomer: '/admin/customer/place-order',
  AdminCheckoutDeliveryAddress: '/admin/customer/place-order/checkout',
  AdminInventory: '/admin/inventory',
  AdminRunners: '/admin/runners',
  AdminRoles: '/admin/runners/roles',
  AdminPartners: '/admin/partners',
  AdminReports: '/admin/reports',
  AdminCoupon: '/admin/coupon',
  AdminCampaign: '/admin/campaign',
  AdminCreateNewRole: '/admin/runners/role/new',
  AdminPermissions: (id: string) => `/admin/runners/role/${id}/permissions`,
  AdminRole: (id: string) => `/admin/runners/role/${id}`,
  AdminCustomerHistory: (id: string) => `/admin/customer/${id}/orders`,
  AdminMemberOrderHistory: (partnerid: string, id: string) =>
    `/admin/partner/${partnerid}/member/${id}/orders`,
  PartnerTransactionHistory: (id: string, type?: string) =>
    `/admin/partners/${id}/${type}transaction-history`,
  AdminPartnerOrderHistory: (id: string) => `/admin/partner/${id}/orders`,
  AdminPharmacyRequestHistory: (id: string) => `/admin/pharmacy/${id}/history`,
  PartnerMembers: (partnerId: string) => `/admin/partner/${partnerId}/members`,
  AdminPlaceOrderForPartner: (partnerId: string) => `/admin/partner/${partnerId}/place-order`,
  AdminPlaceOrderForPartnerMember: (partnerId: string, memberId: string) =>
    `/admin/partner/${partnerId}/${memberId}/place-order`,
  AdminPartnerCheckoutDeliveryAddress: (partnerId: string) =>
    `/admin/partner/${partnerId}/place-order/checkout`,
  AdminPartnerMemberCheckoutDeliveryAddress: (partnerId: string, memberId: string) =>
    `/admin/partner/${partnerId}/${memberId}/place-order/checkout`,
  Verify: '/verify',
  GuestCheckout: '/guest/checkout',
  WellnessCheckers: '/wellness-calculator',
  BmiCalculator: '/wellness-calculator/bmi-checker',
  BmiResult: '/wellness-calculator/bmi-checker/result',
  BmiGoal: '/wellness-calculator/bmi-checker/goal',
  OvulationCalculator: '/wellness-calculator/ovulation-checker',
  OvulationResult: '/wellness-calculator/ovulation-checker/ovulation-result',
  OvulationGoal: '/wellness-calculator/ovulation-checker/ovulation-result/ovulation-goal',
  CalorieCalculator: '/wellness-calculator/calorie-checker',
  CalorieResult: '/wellness-calculator/calorie-checker/calorie-result',
  Consultations: '/book-consultation',
  CustomerMobileView: '/admin/customer/mobile',
  TestPackages: '/tests/test-packages',
  TestPackageView: '/tests/test-packages/:id',
  BookTest: '/tests',
  BookTestHome: '/tests',
  TestStore: '/tests/test-items',
  TestStoreView: '/tests/test-items/:id',
  CustomerWallet: '/customer-wallet',
  TalktoDoctor: '/book-consultation/talk-to-doctor',
  OrderPlaced: (link: string) => `/order-placed/${link}`
};

interface OrderStatusDetails {
  color: string;
  label: string;
  bg: string;
}
export enum orderStatusData {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  AWAITING_COSTING = 'Awaiting Price',
  AWAITING_PAYMENT = 'Awaiting Payment',
  PAID = 'Paid',
  DISPATCH = 'Dispatched',
  DELIVERED = 'Delivered',
  CANCELLED = 'Cancelled',
  CLOSED = 'Closed',
  PAUSED = 'Paused',
  DELIVERED_ON_CREDIT = 'Delivered on Credit',
  DISPATCHED_ON_CREDIT = 'Dispatched on Credit',
  REFUNDED = 'Refunded',
  REFUND_PROCESSING = 'Refund Processing',
  REFUND_FAILED = 'Refund Failed',
  PARTIAL_REFUND = 'Partially Refunded',
  PICKUP = 'Pickup',
  READY_TO_DISPATCH = 'Ready to Dispatch',
  CREDIT_APPROVED = 'Credit Approved',
  RETURNED = 'Returned'
}

export const subscriptionStatus: Record<string, OrderStatusDetails> = {
  Active: {
    color: '#53197E',
    label: 'Active',
    bg: 'purple.100'
  },
  Paused: {
    color: '#689378',
    label: 'Paused',
    bg: 'yellow.100'
  },
  Pending: {
    color: '#689378',
    label: 'Pending',
    bg: 'green.100'
  },
  Closed: {
    color: '#A81818',
    label: 'Closed',
    bg: 'red.100'
  }
};

export const orderStatus: Record<string, OrderStatusDetails> = {
  Active: {
    color: '#53197E',
    label: 'Active',
    bg: 'purple.100'
  },
  false: {
    color: 'rgba(200, 0, 146, 1)',
    label: 'Cancelled',
    bg: 'purple.100'
  },
  true: {
    color: '#53197E',
    label: 'Active',
    bg: 'green.100'
  },
  'Awaiting Payment': {
    color: '#EAA918',
    label: 'Awaiting Payment',
    bg: 'red.200'
  },
  'Awaiting Price': {
    color: '#679378',
    label: 'Awaiting Confirmation',
    bg: 'orange.100'
  },
  Paused: {
    color: '#689378',
    label: 'Paused',
    bg: 'pink.100'
  },
  Pending: {
    color: '#689378',
    label: 'Awaiting Confirmation',
    bg: 'pink.100'
  },
  Paid: {
    color: '#117998',
    label: 'Paid',
    bg: 'cyan.100'
  },
  Dispatched: {
    color: '#53197E',
    label: 'Dispatched',
    bg: 'blue.100'
  },
  Delivered: {
    color: '#53197E',
    label: 'Delivered',
    bg: 'green.100'
  },
  Cancelled: {
    color: 'rgba(200, 0, 146, 1)',
    label: 'Cancelled',
    bg: 'purple.100'
  },
  Closed: {
    color: '#A81818',
    label: 'Closed',
    bg: 'red.100'
  },
  Refunded: {
    color: '#BC8451',
    label: 'Refunded',
    bg: '#F4EBE3'
  },
  'Refund Processing': {
    color: '#689378',
    label: 'Refund Processing',
    bg: 'pink.100'
  },
  'Refund Failed': {
    color: 'rgba(200, 0, 146, 1)',
    label: 'Refund Failed',
    bg: 'purple.100'
  },
  'Partially Refunded': {
    color: '#53197E',
    label: 'Partially Refunded',
    bg: 'blue.100'
  },
  Pickup: {
    color: '#636452',
    label: 'Pickup',
    bg: '#F3F9B6'
  },
  'Ready to Dispatch': {
    color: '#636452',
    label: 'Ready to Dispatch',
    bg: '#F3F9B6'
  },
  'Credit Approved': {
    color: '#117998',
    label: 'Credit Approved',
    bg: 'cyan.100'
  },
  Returned: {
    color: '#BC8451',
    label: 'Returned',
    bg: '#F4EBE3'
  },
};

export const frequency: { name: string; value: string }[] = [
  { name: 'weeks', value: 'weeks' },
  { name: 'months', value: 'months' }
];

export const packages = {
  Pack: 'pack',
  Stash: 'stash'
};

export const mapOrderStageToRoutes: Record<string, string> = {
  CS: 'customer-success',
  PS: 'partner-success',
  IR: 'issues-resolution',
  DISPENSING: 'dispensing',
  PICK_UP: 'pick-up',
  DELIVERY: 'delivery',
  VM: 'vendor-management',
  CO: 'completed'
};

export const orderStageRoutes: Record<string, string> = {
  all: '',
  'customer-success': 'CS',
  'partner-success': 'PS',
  'vendor-management': 'VM',
  'pick-up': 'PICK_UP',
  'issues-resolution': 'IR',
  dispensing: 'DISPENSING',
  delivery: 'DELIVERY',
  completed: 'CO'
};

export const all = Routes.AdminOrdersList;
export const CS = Routes.AdminOrdersCustomerSuccess;
export const PS = Routes.AdminOrdersPartnerSuccess;
export const VM = Routes.AdminOrdersVendorManagement;
export const PU = Routes.AdminOrdersPickup;
export const IR = Routes.AdminOrdersIssuesResolution;
export const DP = Routes.AdminOrdersDispensing;
export const DL = Routes.AdminOrdersDelivery;
export const CO = Routes.AdminOrdersCompleted;
export const subscriptions = Routes.AdminSubscriptionList;

export const orderTabTitles = {
  [all]: 'All Orders',
  [CS]: 'Customer success',
  [PS]: 'Partner success',
  [VM]: 'Vendor management',
  [PU]: 'Pick up',
  [IR]: 'Issues resolution',
  [DP]: 'Dispensing',
  [DL]: 'Delivery',
  [CO]: 'Completed',
  [subscriptions]: 'Subscriptions'
};

export const handleSharePaymentLink = (link: string) => {
  const linkText = `Hi! Could you please help me cover the cost of my medication on Pharmarun? This is crucial for my well-being. Thank you! ${link}`;
  navigator.clipboard.writeText(linkText);
  toast.success('Link copied successfully');
};


export enum IPaymentProviders {
  PAYSTACK = 'paystack',
  BUDPAY = 'budpay',
  PAYFI = 'payfi',
}

export enum IPaymentTypes {
  CARD = 'card',
  WALLET = 'wallet',
  PAY_FOR_ME = 'pay_for_me',
}

export enum PaymentOptionsType {
  CHECKOUT = 'checkout',
  WALLET_PAYMENT = 'walletPayment',
}
