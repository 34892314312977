import { Box } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { Suspense, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from 'components/loading';
import { useStore } from 'store';
import { IPaymentProviders, Routes } from 'constant';

const VerifyPayment = () => {
  const verifyRef = useRef(false);
  const {
    OrderStore: { verifyPayment },
    FinanceStore: { verifyWalletPayment },
    ConsultationStore: { verifyConsultationPayment }
  } = useStore();
  const search = useLocation().search;
  const navigate = useNavigate();

  const trxref = new URLSearchParams(search).get('trxref');
  const reference = new URLSearchParams(search).get('reference');
  const provider = new URLSearchParams(search).get('provider');
  const orderType = new URLSearchParams(search).get('order-type');
  const userId = new URLSearchParams(search).get('userId');
  const orderNumber = new URLSearchParams(search).get('orderNumber');

  const ref = trxref || reference;
  const verify = () => {
    if (ref) {
      const query = `order/verify-payment/${orderType}/${provider}/${ref}`;

      if (provider === IPaymentProviders.PAYFI || provider === IPaymentProviders.BUDPAY) {
        const body = { userId, orderNumber };
        verifyPayment(query, body);
      } else {
        if (orderType === 'wallet') {
          return verifyWalletPayment(`finance/wallet/verify-payment/${provider}/${ref}`);
        }
        if (orderType === 'talkToDoctor') {
          const redirect = () => navigate(Routes.TalktoDoctor);
          return verifyConsultationPayment(
            `contact/verify/doctor-talk/${ref}/${provider}`,
            redirect
          );
        }
        verifyPayment(query);
      }
    }
  };

  useEffect(() => {
    if (verifyRef.current) return;
    verifyRef.current = true;
    verify();
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <Box h="100vh">
        <Loading />
      </Box>
    </Suspense>
  );
};

export default observer(VerifyPayment);
